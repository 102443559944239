<template>
	<mw-dialog v-model="dialog" cancelBtn submitBtn @submit="submit">
		<template v-slot:button>
			<nav-item
				@click="dialog = true"
				icon="mdi-star-plus"
				text="Create Session"
			>
			</nav-item>
		</template>
		<template v-slot:dialog-body>
				<p>Booking a session for {{ participantNames }}.</p>

				<v-checkbox
					hide-details
					class="mt-0"
					v-model="sendMail"
					label="Send a confirmation email"
				></v-checkbox>
		</template>
	</mw-dialog>
</template>

<script>
import NavItem from "@c/navigation/Item";
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "NewSession",
	props: {
		value: { type: Array },
	},
	inject: ["projectId", "surveyId"],
	data: () => {
		return {
			sendMail: true,
			dialog: false,
		};
	},
	computed: {
		responses() {
			return this.$store.state.surveyResponses.data;
		},
		users() {
			return this.$store.state.users.data;
		},
		project() {
			return this.$store.state.projects.data[this.projectId];
		},
		participantNames() {
			const self = this;
			return self.value
				.map((response) => {
					let participant = response.participant;
					return self.users[participant].name;
				})
				.join(", ");
		},
	},
	components: {
		MwDialog,
		NavItem,
	},
	methods: {
		submit() {
			const self = this;
			self.value.forEach((response) => {
				let user = response.participant;
				let doc = {
					project: self.projectId,
					methodology: self.project.methodology || "",
					appointment_status: "provisional",
				};
				self.$store.dispatch("appointments/insert", doc).then((id) => {
					self.$store.dispatch("appointmentParticipants/insert", {
						user,
						appointment: id,
						response: response.id
					}).then ( ( appointment_participant ) => {
						if (self.sendMail) {
							self.$store.dispatch("emails/sendOut", { email: "appointment_confirmation", values: {appointment_participant } }).then( () => {
								self.$root.$emit("showToast", "Sent")
							}).catch( () => {
								self.$root.$emit("showToast", "Error sending", true)
							})
						}
					})
					self.dialog = false;
					self.$emit("clear");
				});
			});
		},
	},
};
</script>
