<template>
	<v-sheet>
		<v-text-field v-model="search" label="search"></v-text-field>
		<v-data-table
			:multi-sort="multiSort"
			:headers="headers"
			:show-select="showSelect"
			:value="selected"

			@click:row="(v) => $emit('click', v.id)"
			@input="(v) => $emit('select', v)"
			@current-items="(v) => $emit('sort', v)"
			:search="search"
			:items="items"
		>
			<template v-slot:item.prepend="{ item }" v-if="showPrepend">
				<slot name="prepend" :item="item"></slot>
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ item.created_at.toDate().toLocaleDateString() }}
				{{ item.created_at.toDate().toLocaleTimeString() }}
			</template>
			<template v-slot:footer.prepend v-if="showExport">
				<Export
					:rows="filteredItems"
					:columns="headers"
					name="responses"
				></Export>
			</template>
			<template v-slot:foot>
				<slot name="foot"></slot>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import Export from "@c/downloads/Export";
export default {
	name: "ResponseTable",
	props: {
		value: { type: Array, default: () => [] },
		questions: { type: Array, default: () => [] },
		showSelect: { type: Boolean, default: true },
		showContacts: { type: Boolean, default: true },
		showDate: { type: Boolean, default: true },
		showExport: { type: Boolean, default: true },
		multiSort: { type: Boolean, default: true },
		showPrepend: { type: Boolean, default: false },
		selected: { type: Array, default: () => [] },
	},
	data() {
		return {
			search: null,
		};
	},
	computed: {
		users() {
			return this.$store.state.users.data;
		},
		responses() {
			const self = this;
			return self.value
				.filter((a) => self.$store.state.surveyResponses.data[a])
				.map((a) => self.$store.state.surveyResponses.data[a]);
		},
		filteredItems() {
			if (this.selected.length) {
				return this.selected;
			}
			return this.items;
		},
		items() {
			const self = this;
			return self.responses.map((i) => {
				var row = {};
				if (self.showPrepend) {
					row.prepend = null;
				}
				if (self.showContacts) {
					let user = self.users[i.participant];
					if (user) {
						row.name = user.name;
						row.email = user.email;
						row.phone = user.phone;
					}
				}
				for (var questionId in i.data) {
					let value = i.data[questionId];
					const hierarchical = self.$store.getters[
						"questions/isHierarchical"
					](questionId);
					if (hierarchical) {
						for (var subq in value) {
							row[`${questionId}-${subq}`] = self.$store.getters[
								"questions/parseValueToString"
							](questionId, value[subq]);
						}
					} else {
						row[questionId] = self.$store.getters[
							"questions/parseValueToString"
						](questionId, value);
					}
				}
				row.participant = i.participant;
				row.created_at = i.created_at;
				row.id = i.id;
				return row;
			});
		},
		headers() {
			const self = this;
			let cols = [];
			if (self.showPrepend) {
				cols.push({ value: "prepend", text: " " });
			}
			cols.push({
				value: "created_at",
				text: "Created At",
			});
			if (this.showContacts) {
				cols.push({ value: "name", text: "Name" });
				cols.push({ value: "phone", text: "Phone" });
				cols.push({ value: "email", text: "Email" });
			}
			self.questions.forEach((questionId) => {
				const hierarchical = self.$store.getters[
					"questions/isHierarchical"
				](questionId);
				const title = self.$store.getters["questions/title"](questionId);
				if (!hierarchical) {
					cols.push({
						value: questionId,
						text: title,
					});
				} else {
					let subquestions = self.$store.getters["questions/subquestions"](
						questionId
					);
					subquestions.forEach((subquestion) => {
						cols.push({
							value: `${questionId}-${subquestion.value}`,
							text: `${subquestion.text} - ${title}`,
						});
					});
				}
			});
			return cols;
		},
	},
	components: {
		Export,
	},
	mounted() {
		// var tables = document.getElementsByTagName("table");
		// for (var i = 0; i < tables.length; i++) {
		// 	this.mwutils.resizableGrid(tables[i]);
		// }
	},
};
</script>
