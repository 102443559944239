<template>
	<mw-dialog v-model="dialog" submitBtn cancelBtn @submit="save" large>
		<template v-slot:button>
			<nav-item
				@click="dialog = true"
				text="Edit"
				icon="mdi-pencil-circle"
			>
			</nav-item>
		</template>
		<template v-slot:dialog-body>
            <v-autocomplete label="Participant" :items="users" :return-object="false" clearable v-model="response.participant"></v-autocomplete>
            <v-simple-table>
                <tbody>
                    <template v-for="question in questions">

                        <tr v-if="question.type !== 'matrix'" :key="question.id"> 
                            <th><span v-html="mwsurveyutils.renderQuestionContent(question.id)"></span></th>
							<response-input
								v-model="response.data[question.id]"
								:id="question.id"
							></response-input>
                        </tr>
                        <template v-else>
                            <tr v-for="subquestion in question.subquestions" :key="`${question.id}-${subquestion.value}`"> 
                                <th><span v-html="`${mwsurveyutils.renderQuestionContent(question.id)} - ${subquestion.text}`" ></span></th>
                                <td>
                                    <response-input
                                    v-if="response.data[question.id]"
                                        v-model="
                                            response.data[question.id][subquestion.value]
                                        "
                                        :id="question.id"
                                    ></response-input>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </v-simple-table>
        </template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import NavItem from "@c/navigation/Item";
import ResponseInput from "@c/responses/ResponseInput";
export default {
	name: "EditResponseButton",
	props: {
		id: { type: String }, // the id of the response,
	},
    data: () => ({
        dialog: false
    }),
	computed: {
		response() {
			return this.$store.state.surveyResponses.data[this.id];
		},
        survey(){
            return this.response ? this.$store.state.surveys.data[this.response.survey] : false;
        },
        users(){
            let users = this.$store.getters.getByKey("users", "role", "participant", true );
            return this.mwutils.itemsArray(users)
        }, 
        questions(){
            const self = this;
            return self.survey.questions.map( q => self.$store.state.questions.data[q] ).filter( a => a)
        }
	},
	components: {
		NavItem,
		MwDialog,
        ResponseInput
	},
    methods: {
        save(){
            this.$store.dispatch("surveyResponses/patch", {id: this.id, data: this.response.data, participant: this.response.participant  })
        },
    },
    created(){
        this.$store.dispatch("users/fetchAndAdd", { clauses: {where: [ ["role", "==", "participant"]], limit: 0  }})
    }
};
</script>
