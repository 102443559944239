<template>
    <v-component :is="this.component" :value="value" @input="v => $emit('input', v)" :multiple="multiple" :items="items"></v-component>
</template>


<script>
export default {
    name: "ResponseInput", 
    props: {
        id: {type: String}, 
        value: {type: [String, Array]}
    }, 
    computed: {
        question(){
            return this.$store.state.questions.data[this.id];
        },
        questionType(){
            let type = null;
            if( this.question ){
               type = this.question.type;
            }
            return type;
        },
        component(){
            let component;
            switch(this.questionType) {
                case "matrix":
                case "checkboxes":
                case "tiles":
                    component = "VSelect" 
                    break;
                default:
                    component = "VTextField";
            }
            return component
        }, 
        items(){
            let items;
            switch(this.questionType) {
                case "matrix":
                    items = Object.values(this.question.subquestions);
                    break;
                case "tiles":
                case "checkboxes":
                    items = Object.values(this.question.answer_options);
                    break;
                default:
                    items = [];
            }
            return items
        }, 
        multiple(){
            return this.question.multiple;
        }
    }
}
</script>