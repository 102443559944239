<template>
	<mw-dialog v-model="dialog" cancel-btn>
		<template v-slot:button>
            <nav-item icon="mdi-magnify" text="View Availability" @click="dialog = true"></nav-item>
		</template>
		<template v-slot:dialog-body>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Start</th>
                        <th>End</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(d, i) in dates" :key="i">
                        <td><v-chip :color="colors[d.status]">{{d.status}}</v-chip></td>
                        <td>{{d.start.toDate().toLocaleDateString()}} {{d.start.toDate().toLocaleTimeString()}}</td>
                        <td>{{d.end.toDate().toLocaleDateString()}} {{d.end.toDate().toLocaleTimeString()}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import NavItem from "@c/navigation/Item";
export default {
	name: "ViewSingleAvailability",
	props: {
		projectId: { type: String },
		participantId: { type: String },
	},
	data: () => {
		return {
			dialog: false,
            selected: null,
            colors: {
                available: 'primary', 
                preferred: 'green', 
                unavailable: 'grey'
            }
		};
	},
	computed: {
		user() {
			return this.$store.state.users.data[this.participantId];
		},
		project() {
			return this.$store.state.projects.data[this.projectId];
		},
		availability() {
			return this.$store.getters["participantAvailability/userForProject"](
				this.participantId,
				this.projectId
			);
		},
        dates(){
            const self = this;
            let dates = self.availability.flatMap( a => a.availability );
            dates = this.mwutils.sortByDate(dates, "start", -1);
            return dates;
        }
	},
	components: {
		MwDialog,
        NavItem
	},
	created() {
        if( this.participantId && this.projectId ){
            this.$store.dispatch("participantAvailability/fetchByUserForProject", {
                participant: this.participantId,
                project: this.projectId,
            });
        }
	},
};
</script>
