<template>
	<v-sheet v-if="project">
		<h4>{{ project.internal_name }}</h4>
		<mw-select
			storeModule="surveys"
			label="Screener"
			:include="project.screeners"
			v-model="screener"
			itemText="internal_name"
		></mw-select>
		<response-table
			v-if="survey"
			:value="filteredResponses"
			:questions="questions"
			:show-export="false"
			:selected="selected"
			@sort="sort"
			@select="(v) => (selected = v)"
		>
			<template v-slot:foot>
				<tr>
					<td colspan="5">
						<v-btn small text @click="clearFilter">Clear Filter</v-btn>
					</td>
					<filter-footer-cell
						v-for="question in questions"
						:key="question"
						:id="question"
						:responses="responses"
						v-model="filter[question]"
					></filter-footer-cell>
				</tr>
			</template>
		</response-table>
		<secondary-navigation show-back>
			<template v-slot:main>
				<edit-response-button
					v-if="selected.length == 1"
					:id="selected[0].id"
					show-export
					show-contacts
					show-date
				></edit-response-button>
				<view-single-availability
					:participantId="selected[0].participant"
					:projectId="id"
					v-if="selected.length == 1"
				></view-single-availability>
				<nav-item
					v-if="project.include_scheduling"
					@click="goToScheduling"
					icon="mdi-calendar-plus"
					text="Go to Scheduling"
				></nav-item>
				<new-session
					v-if="selected.length"
					:value="selected"
					@clear="selected = []"
				></new-session>
				<nav-item
					v-if="selected.length"
					@click="exclude"
					icon="mdi-close-circle"
					text="Trash participant"
				></nav-item>
				<nav-item
					v-if="selected.length"
					@click="addToView"
					icon="mdi-check-circle"
					text="Add to Filter"
				></nav-item>
			</template>
			<template v-slot:append>
				<v-list-item>
					<v-list-item-content> </v-list-item-content>
					<v-list-item-action>
						<v-avatar color="success" dark class="white--text">{{
							responsesInView.length || "0"
						}}</v-avatar>
					</v-list-item-action>
				</v-list-item>
				<edit-project-notes v-model="id"></edit-project-notes>
			</template>
		</secondary-navigation>
	</v-sheet>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import MwSelect from "@c/ui/MwSelect";
import NavItem from "@c/navigation/Item";
import ResponseTable from "@c/responses/ResponseTable";
import ViewSingleAvailability from "@c/scheduler/ViewSingleAvailability";
import FilterFooterCell from "@c/responses/FilterFooterCell";
import EditResponseButton from "@c/responses/EditResponseButton.vue";
import NewSession from "@c/responses/NewBookingFromResponse.vue";
import EditProjectNotes from "@c/edits/EditProjectNotes";
export default {
	name: "ProjectCandidates",
	props: {
		id: { type: String },
	},
	components: {
		SecondaryNavigation,
		MwSelect,
		NavItem,
		ResponseTable,
		FilterFooterCell,
		EditResponseButton,
		NewSession,
		EditProjectNotes,
		ViewSingleAvailability,
	},
	data: () => {
		return {
			screener: null,
			selected: [],
			order: [],
			responsesInView: [],
			filter: {},
		};
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
		survey() {
			return this.$store.state.surveys.data[this.screener];
		},
		responses() {
			let id = this.screener;
			return Object.values(this.$store.state.surveyResponses.data)
				.filter((a) => a.survey == id)
				.map((a) => a.id);
		},
		filteredResponses() {
			const self = this;
			let responses = self.responses;
			responses = responses.map(
				(r) => self.$store.state.surveyResponses.data[r]
			);
			if (self.project.exclude_participants) {
				responses = responses.filter(
					(a) => !self.project.exclude_participants.includes(a.participant)
				);
			}
			if (self.responsesInView) {
				responses = responses.filter(
					(a) => !self.responsesInView.includes(a.id)
				);
			}
			for (var key in self.filter) {
				if (self.filter[key].length) {
					responses = responses.filter((response) =>
						self.matchAnswer(response.data[key], self.filter[key])
					);
				}
			}
			return responses.map((r) => r.id);
		},
		questions() {
			const self = this;
			let questions = this.survey.questions;
			return questions.filter((q) => {
				let question = self.$store.state.questions.data[q];
				return question && question.type !== "html";
			});
		},
	},
	watch: {
		project(v) {
			if (!this.screener && v.screeners) {
				this.screener = v.screeners[0];
			}
		},
		screener(v) {
			if (v) {
				this.$store.dispatch("surveyResponses/fetchBySurvey", v);
			}
		},
	},
	methods: {
		sort(v) {
			this.order = v.map((a) => a.id);
		},
		exclude() {
			const self = this;
			let ids = self.selected.map((a) => a.participant);
			let exclude = self.project.exclude_participants.concat(ids);
			exclude = exclude.filter((a) => typeof a == "string");

			self.$store
				.dispatch("projects/patch", {
					id: self.id,
					exclude_participants: exclude,
				})
				.then(() => {
					self.$root.$emit("showToast", "Excluded");

					self.selected = [];
				});
		},
		addToView() {
			let selected = this.selected.map((a) => a.id);
			this.responsesInView = this.responsesInView.concat(selected);
			this.$root.$emit("showToast", "Added to Filter");
			this.selected = [];
		},
		matchAnswer(a1, a2) {
			if (!a1) {
				a1 = [];
			}
			if (!a2) {
				a2 = [];
			}
			if (typeof a1 == "string") {
				a1 = [a1];
			}
			if (typeof a1 == "undefined") {
				a1 = [];
			}
			let match = a2.find((a) => a1.includes(a));
			return match ? true : false;
		},
		clearFilter() {
			for (var key in this.filter) {
				this.filter[key] = [];
			}
			this.selected = [];
			this.responsesInView = [];
		},
		goToScheduling() {
			const self = this;
			let participants = self.responses;

			if (self.responsesInView.length) {
				participants = participants.filter((a) =>
					self.responsesInView.includes(a)
				);
			}

			participants = participants.map((a) => {
				return self.$store.state.surveyResponses.data[a].participant;
			});
			participants = participants.filter((a, i, arr) => arr.indexOf(a) == i);

			this.$router.push({
				name: "ProjectSchedule",
				params: {
					id: self.id,
					filter: participants,
				},
			});
		},
	},
	provide() {
		return {
			projectId: this.id,
			surveyId: this.screener,
		};
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
	},
};
</script>
