<template>
	<td>
		<v-avatar :color="haveValue ? 'success' : 'lightest'" :class="{'white--text': haveValue}" size="25">{{
			value ? value.length : 0
		}}</v-avatar>
        <mw-dialog v-model="dialog" small @submit="submit" submit-btn cancel-btn>
            <template v-slot:button>
                <v-btn icon @click="dialog = true"><v-icon>mdi-plus-circle</v-icon></v-btn>
            </template>
            <template v-slot:dialog-body>
                <v-checkbox multiple hide-details v-for="(answer, i) in answers" :key="i" v-model="selected" :value="answer" :label="answer" ></v-checkbox>
            </template>
        </mw-dialog>
	</td>
</template>


<script>
import MwDialog from "@c/ui/MwDialog";

export default {
    name: "FilterFooterCell", 
    props: {
        value: {type: Array}, 
        responses: {type: Array}, 
        id: {type: String}
    }, 
    computed: {
        haveValue(){
            return this.value && this.value.length;
        },
        question(){
            return this.$store.state.questions.data[this.id]
        }, 
        responseData(){
            const self = this;
            return self.responses.map( m => {
                return self.$store.state.surveyResponses.data[m]
            })
        },
        answers(){
            const self = this;
            let answers = self.responseData.flatMap( r => {
                return r.data[self.id]
            })
            answers = answers.filter( a => typeof a !== "undefined" );
            answers = answers.filter( (a, i, arr ) => arr.indexOf(a) == i );
            return answers;
        }
    }, 
    data: () => {
        return {
            dialog: false, 
            selected: []
        }
    }, 
    components: {
        MwDialog
    }, 
    methods: {
       
        submit(){
            this.$emit("input", this.selected )
        }
    }, 
    created(){
        this.selected = this.value;
    }
}
</script>